@keyframes borderGradient {
    0% {
        border-image-source: linear-gradient(45deg, #4d1caf, #fff, rgba(132, 136, 255, 1));
    }
    50% {
        border-image-source: linear-gradient(135deg, rgba(132, 136, 255, 1), #4d1caf, #fff);
    }
    100% {
        border-image-source: linear-gradient(225deg, #fff, rgba(132, 136, 255, 1), #4d1caf);
    }
}

.cta {
    display: flex;
    align-items: center;
    margin-top: 30px;
    padding: 0px 55px 0 45px;
    text-decoration: none;
    font-size: 28px;
    text-transform: uppercase;
    color: white;
    background: transparent;
    transition: 1s;
    box-shadow: 6px 6px 0 black;
    transform: skewX(-15deg);
    border: 2px solid transparent;
    border-image: linear-gradient(45deg, #4d1caf, #fff, rgba(132, 136, 255, 1)) 1;
    animation: borderGradient 5s alternate infinite;
    background-clip: padding-box;

    @media (max-height: 799px) and (min-width: 1024px) {
        font-size: 22px;
        padding: 0px 35px;
    }

    @media (max-width: 767px) {
        font-size: 22px;
        padding: 5px 30px;
    }
}

.cta:focus {
    outline: none;
}

.cta:hover {
    @media (min-width: 768px) {
        transition: 0.5s;
        box-shadow: 10px 10px 0 #4d1caf;
    }
}

.cta span:nth-of-type(2) {
    transition: 0.5s;
    margin-right: 0px;
}

.cta:hover span:nth-of-type(2) {
    @media (min-width: 768px) {
        transition: 0.5s;
    }
}

.cta {
    @media (max-width: 768px) {
        transition: 0.5s;
        box-shadow: 10px 10px 0 #4d1caf;
    }
}

span {
    transform: skewX(15deg);
}

span:nth-of-type(2) {
    width: 20px;
    margin-left: 30px;
    position: relative;
    top: 12%;
}

/**************SVG****************/

path.one {
    transition: 0.4s;
    transform: translateX(-60%);
}

path.two {
    transition: 0.5s;
    transform: translateX(-30%);
}

.cta path.three {
    animation: color_anim 2s infinite 2s;
}

.cta path.one {
    transform: translateX(0%);
    animation: color_anim 2s infinite 2.8s;
}

.cta path.two {
    transform: translateX(0%);
    animation: color_anim 2s infinite 2.4s;
}

/* SVG animations */

@keyframes color_anim {
    0% {
        fill: white;
    }

    50% {
        fill: #4d1caf;
    }

    100% {
        fill: white;
    }
}
